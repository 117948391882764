import React from 'react';
import { makeStyles } from 'tss-react/mui';
import stylesheet, { headerHeight } from '../constants/stylesheet';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles<void, 'root'>({ name: 'Header' })((_theme, _params, classes) => ({
  root: {
    display: 'grid',
    height: headerHeight.AfterMobile,

    [mediaQueries.Mobile]: {
      height: headerHeight.Mobile,
    },
  },
  logo: {
    gridColumnStart: 1,
    gridRowStart: 1,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    textTransform: 'uppercase',
    opacity: 0,
    background: 'rgba(255, 255, 255, 1)',

    transition: 'opacity ease-in-out 0.6s',
    willChange: 'opacity',
    pointerEvents: 'none',

    [mediaQueries.Mobile]: {
      display: 'none',
    },

    [mediaQueries.AfterMobile]: {
      '.home-page &': {
        display: 'none',
      },

      [`.${classes.root}:hover &`]: {
        opacity: 0,
      },

      '[data-scrolled="false"] &': {
        opacity: 1,
      },

      '[data-scrolled="true"] &': {
        opacity: 0,
      },
    },
  },
  nav: {
    gridColumnStart: 1,
    gridRowStart: 1,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: 'rgba(255, 255, 255, 1)',
    opacity: 0,
    transition: 'opacity ease-in-out 0.6s',
    willChange: 'opacity',

    [mediaQueries.Mobile]: {
      '[data-scrolled="false"] &': {
        opacity: 1,
      },

      '[data-scrolled="true"] &': {
        opacity: 0,
      },

      '[data-scrolled="true"][data-scrolling="up"] &': {
        opacity: 1,
      },
    },

    [mediaQueries.AfterMobile]: {
      '[data-scrolled="false"] &': {
        opacity: 0,
      },
      '[data-scrolled="false"] &:hover, .home-page &': {
        opacity: 1,
      },

      '[data-scrolled="true"] &, .home-page [data-scrolling="down"] &': {
        opacity: 0,
      },
      '[data-scrolled="true"] &:hover, [data-scrolled="true"][data-scrolling="up"] &':
      {
        opacity: 1,
      },
    },
  },
  menuItem: {
    margin: `0 .5rem`,

    '.no-touch-device &': {
      ...stylesheet.LinkAnim,
    },
  },
}));

export default useStyles;
