import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { headerHeight } from '../../constants/stylesheet';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = makeStyles({ name: 'FullscreenSlide' })(() =>
({
  root: {
    [mediaQueries.AfterMobile]: {
      position: 'relative',
      top: `-${headerHeight.AfterMobile}`,
      height: '100vh',
    },
  },

  figure: {
    [mediaQueries.AfterMobile]: {
      position: 'absolute',
      inset: 0,
    },
  }
}));

export default useStyles;
