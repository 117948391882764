import React from 'react';

const ThumbnailsIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.25C0 0.559644 0.559644 0 1.25 0C1.94036 0 2.5 0.559644 2.5 1.25C2.5 1.94036 1.94036 2.5 1.25 2.5C0.559644 2.5 0 1.94036 0 1.25Z"
        fill="black"
      />
      <path
        d="M12.5 1.25C12.5 0.559644 13.0596 0 13.75 0C14.4404 0 15 0.559644 15 1.25C15 1.94036 14.4404 2.5 13.75 2.5C13.0596 2.5 12.5 1.94036 12.5 1.25Z"
        fill="black"
      />
      <path
        d="M12.5 13.75C12.5 13.0596 13.0596 12.5 13.75 12.5C14.4404 12.5 15 13.0596 15 13.75C15 14.4404 14.4404 15 13.75 15C13.0596 15 12.5 14.4404 12.5 13.75Z"
        fill="black"
      />
      <path
        d="M0 13.75C0 13.0596 0.559644 12.5 1.25 12.5C1.94036 12.5 2.5 13.0596 2.5 13.75C2.5 14.4404 1.94036 15 1.25 15C0.559644 15 0 14.4404 0 13.75Z"
        fill="black"
      />
      <path
        d="M0 7.5C0 6.80964 0.559644 6.25 1.25 6.25C1.94036 6.25 2.5 6.80964 2.5 7.5C2.5 8.19036 1.94036 8.75 1.25 8.75C0.559644 8.75 0 8.19036 0 7.5Z"
        fill="black"
      />
      <path
        d="M6.25 7.5C6.25 6.80964 6.80964 6.25 7.5 6.25C8.19036 6.25 8.75 6.80964 8.75 7.5C8.75 8.19036 8.19036 8.75 7.5 8.75C6.80964 8.75 6.25 8.19036 6.25 7.5Z"
        fill="black"
      />
      <path
        d="M6.25 1.25C6.25 0.559644 6.80964 0 7.5 0C8.19036 0 8.75 0.559644 8.75 1.25C8.75 1.94036 8.19036 2.5 7.5 2.5C6.80964 2.5 6.25 1.94036 6.25 1.25Z"
        fill="black"
      />
      <path
        d="M6.25 13.75C6.25 13.0596 6.80964 12.5 7.5 12.5C8.19036 12.5 8.75 13.0596 8.75 13.75C8.75 14.4404 8.19036 15 7.5 15C6.80964 15 6.25 14.4404 6.25 13.75Z"
        fill="black"
      />
      <path
        d="M12.5 7.5C12.5 6.80964 13.0596 6.25 13.75 6.25C14.4404 6.25 15 6.80964 15 7.5C15 8.19036 14.4404 8.75 13.75 8.75C13.0596 8.75 12.5 8.19036 12.5 7.5Z"
        fill="black"
      />
    </svg>
  );
};

export default ThumbnailsIcon;
