import { Link } from 'gatsby';
import React, { FC } from 'react';
import { TSlideLink } from '../@types';
import useStyles from './ExplorationDetailFooter.styles';

const ExplorationDetailFooter: FC<{
  slides: Array<{ caption?: string; slide_link: TSlideLink }>;
  cur: number;
}> = ({ cur, slides, children }) => {
  const { classes: cls } = useStyles();
  return (
    <div className={cls.root}>
      {slides.map(({ caption, slide_link }, i) => {
        return (
          <div
            className={cls.explorationsLegend}
            data-active={cur === i}
            key={i}
          >
            {caption && <p>{caption}</p>}
            {slide_link?.url ? (
              slide_link.url.startsWith('http') ? (
                <a
                  href={slide_link.url}
                  rel="noreferrer"
                  target="_blank"
                  className={cls.explorationsLegendLink}
                />
              ) : (
                <Link
                  to={`${slide_link?.url}`}
                  className={cls.explorationsLegendLink}
                />
              )
            ) : null}
          </div>
        );
      })}
      {children}
    </div>
  );
};

export default ExplorationDetailFooter;
