import * as React from 'react';
import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { TExplorationDetail } from '../@types';
import { Link, navigate } from 'gatsby';
import useStyles from './Detail.styles';
import Close from './Icons/Close';
import ThumbnailsIcon from './Icons/ThumbnailsIcon';
import DesktopDiaporama from './DesktopDiaporama';
import MobileDiaporama from './MobileDiaporama';
import classNames from 'classnames';
import { useResponsive } from '../@context/Responsive';
import computeExplorationThumbnails from '../utils/computeExplorationThumbnails';
import ExplorationOverview from './ExplorationOverview';
import SEO from './Seo';
import { locales } from '../gatsby/createPages';

const ExplorationDetail: FC<{ exploration: TExplorationDetail }> = ({
  exploration,
}) => {
  const {classes: cls} = useStyles();
  const { title, body, seo_title, seo_description, seo_image } =
    exploration.data;
  const [showOverview, setShowOverview] = useState(true);
  const { steps } = useResponsive();
  const [startingSlideId, setstartingSlideId] = useState('');

  const handleKeyPress = useCallback(
    (key: KeyboardEvent) => {
      switch (key.keyCode) {
        case 27: // esc
          navigate(`/${exploration!.lang}/transformation`);
          break;

        default:
          break;
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (!exploration) {
      return;
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const overviewThumbs = body
    ? useMemo(() => computeExplorationThumbnails(body), [body])
    : null;

  const handleOverviewItemClick = (id: string) => {
    setShowOverview(false);
    setstartingSlideId(id);
  };

  return (
    <>
      <SEO
        title={seo_title ?? ''}
        description={seo_description ?? ''}
        image={seo_image?.fixed?.src || ''}
      />
      <section className={cls.root} data-type="exploration">
        <header className={cls.header}>
          <Link
            to={`/${locales[exploration.lang]}/transformation`}
            className={cls.iconBtn}
          >
            <Close />
          </Link>
          <div className={cls.headerContent}>
            <h2 className={classNames(cls.title, cls.uppercaseTitle)}>
              {title?.text}
            </h2>
          </div>
          <button
            className={classNames(cls.iconBtn, cls.thambnailsBtn)}
            onClick={() => setShowOverview(true)}
          >
            <ThumbnailsIcon />
          </button>
        </header>
        <article className={cls.content}>
          {body && steps.includes('AfterMobile') && (
            <DesktopDiaporama
              slides={body}
              lang={exploration.lang}
              type="exploration"
              startingSlideId={startingSlideId}
            />
          )}
          {body && steps.includes('Mobile') && (
            <MobileDiaporama
              slides={body}
              lang={exploration.lang}
              type="exploration"
              startingSlideId={startingSlideId}
            />
          )}
        </article>
        {overviewThumbs && (
          <div className={cls.overlay} data-show={showOverview}>
            <ExplorationOverview
              items={overviewThumbs}
              title={title?.text || ''}
              goTo={(v: string) => handleOverviewItemClick(v)}
              lang={exploration.lang}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default ExplorationDetail;
