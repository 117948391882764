import * as React from 'react';
import { PageProps } from 'gatsby';
import { createContext, FC, useCallback, useContext } from 'react';
import Explorations from '../components/Explorations';
import type { ExplorationPageContext } from '../gatsby/createPages';
import { useState } from 'react';
import useStyles from './WithProjects.styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ResponsiveProvider } from '../@context/Responsive';
import mediaQueries from '../constants/mediaQueries';
import { TExplorationDetail, TExplorationListItem } from '../@types';
import ExplorationDetail from '../components/ExplorationDetail';

type TExplorationsContext = {
  updateExplorations: (explorations: TExplorationListItem[]) => void;
  explorations: TExplorationListItem[];
  updateExploration: (project?: TExplorationDetail) => void;
  exploration?: TExplorationDetail;
};

const ExplorationContext = createContext<TExplorationsContext>({} as never);

ExplorationContext.displayName = 'ExplorationContext';
export const useExplorations = () => useContext(ExplorationContext);

export const WithExplorations: FC<
  Omit<PageProps<unknown, ExplorationPageContext>, 'children'>
> = ({ pageContext: { explorationId, lang }, children }) => {
  const { classes: cls } = useStyles();

  const [explorations, setExplorations] = useState<TExplorationListItem[]>([]);
  const [exploration, setExploration] = useState<TExplorationDetail>();

  const updateExplorations = useCallback(
    (items: TExplorationListItem[]) => {
      if (
        items?.length &&
        explorations.map((p) => p.id).join(',') !=
        items.map((p) => p.id).join(',')
      ) {
        setExplorations(items);
      }
    },
    [explorations]
  );

  const updateExploration = useCallback(
    (item?: TExplorationDetail) => {
      if (exploration?.id != item?.id) {
        setExploration(item);
      }
    },
    [exploration]
  );

  return (
    <ResponsiveProvider mediaQueries={mediaQueries}>
      <ExplorationContext.Provider
        value={{
          updateExploration,
          exploration,
          updateExplorations,
          explorations,
        }}
      >
        <section className={cls.content}>
          <Explorations />
        </section>

        <TransitionGroup>
          {exploration && (
            <CSSTransition
              timeout={500}
              key={explorationId}
              classNames={{
                enter: cls.pageEnter,
                enterActive: cls.pageEnterActive,
                exit: cls.pageExit,
                exitActive: cls.pageExitActive,
              }}
            >
              <div className={cls.page}>
                <ExplorationDetail exploration={exploration} />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        {children}
      </ExplorationContext.Provider>
    </ResponsiveProvider>
  );
};

export default WithExplorations;
