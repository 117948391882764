import Player from '@vimeo/player';
import React, { FC, useCallback } from 'react';
import { SlideVideo } from '../../@types';
import useStyles from './SlideBlockVideo.styles';

const SlideBlockVideo: FC<SlideVideo & { active: boolean }> = ({
  active,
  video,
  children,
}) => {
  const {classes: cls} = useStyles();
  let player: Player;

  const ref = useCallback(
    (node) => {
      if (!node) return;

      player = new Player(node);
      if (active) {
        player
          .play()
          .then(() => {})
          .catch((error) => console.log(error));
      } else {
        player
          .pause()
          .then(() => {})
          .catch((error) => console.log(error));
      }
    },
    [active]
  );

  if (!video) {
    return null;
  }

  const { provider_name, id, title, video_id } = video;

  return (
    <div className={cls.root}>
      {provider_name === 'Vimeo' && (
        <iframe
          key={id}
          title={title}
          ref={ref}
          src={`https://player.vimeo.com/video/${video_id}?title=0&byline=0&background=true`}
        />
      )}
      {children}
    </div>
  );
};

export default SlideBlockVideo;
