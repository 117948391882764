import React, { FC } from 'react';
import { TSlideSideBySideImage } from '../../@types';
import useStyles from './SideBySideImageSlide.styles';
import SlideBlockImage from './SlideBlockImage';

const SideBySideImageSlide: FC<TSlideSideBySideImage> = ({
  left_image,
  right_image,
  children,
}) => {
  const {classes: cls} = useStyles();
  const { gatsbyImageData: leftImage, alt: leftAlt } = left_image;
  const { gatsbyImageData: rightImage, alt: rightAlt } = right_image;

  return (
    <div className={cls.root}>
      <div className={cls.slide}>
        {leftImage && <SlideBlockImage {...left_image} side="right" />}
      </div>
      <div className={cls.slide}>
        {rightImage && <SlideBlockImage {...right_image} side="left" />}
      </div>
      {children}
    </div>
  );
};

export default SideBySideImageSlide;
