import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  TExplorationSlides,
  TProjectSlides,
  TSlideLink,
  TSlideSideBySideImage,
  TSlideSingleImage,
  TSlideVideo,
} from '../@types';
import useStyles from './Diaporama.styles';
import FullscreenSlide from './Slides/FullscreenSlide';
import SingleImageSlide from './Slides/SingleImageSlide';
import SideBySideImageSlide from './Slides/SideBySideImageSlide';
import ProjectDetailFooter from './ProjectDetailFooter';
import ExplorationDetailFooter from './ExplorationDetailFooter';
import SlideBlockVideo from './Slides/SlideBlockVideo';

const convertSliderCounter = (n: number, l: string) =>
  n.toLocaleString(l, {
    minimumIntegerDigits: 2,
  });

type Props = {
  lang: string;
  slides: TProjectSlides[] | TExplorationSlides[];
  type: 'project' | 'exploration';
  startingSlideId?: string;
};

const DesktopDiaporama: FC<Props> = ({
  slides,
  lang,
  type,
  startingSlideId,
}) => {
  const {classes: cls} = useStyles();

  const [activeSlide, setActiveSlide] = useState(0);
  const totalSlides = slides.length;

  useEffect(() => {
    if (startingSlideId) {
      const newActive = slides.findIndex(({ id }) => id === startingSlideId);
      setActiveSlide(newActive ? newActive : 0);
    }
  }, [startingSlideId]);

  const go = (n: number) => setActiveSlide(n);
  const next = () => {
    if (!totalSlides) {
      return;
    }
    go((activeSlide + 1) % totalSlides);
  };
  const previous = () => {
    if (!totalSlides) {
      return;
    }
    const p = activeSlide - 1;
    go(p < 0 ? totalSlides - 1 : p);
  };

  const handleKeyPress = useCallback(
    (key: KeyboardEvent) => {
      switch (key.keyCode) {
        case 39:
          next();
          break;
        case 37:
          previous();
          break;

        default:
          break;
      }
    },
    [next, previous]
  );

  useEffect(() => {
    if (!totalSlides) {
      return;
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (!totalSlides) {
    return null;
  }

  const footerSlides =
    type === 'exploration'
      ? React.useMemo(
          () =>
            (slides as TExplorationSlides[])
              .filter(({ primary }) => !!primary)
              .reduce((prev, cur) => {
                const { primary } = cur;
                return [
                  ...prev,
                  {
                    caption: primary?.caption,
                    slide_link: primary?.slide_link,
                  },
                ];
              }, [] as Array<{ caption?: string; slide_link: TSlideLink }>),
          [slides]
        )
      : null;

  return (
    <div className={cls.root}>
      <div className={cls.slides}>
        {slides.map((b, i) => {
          switch (b.slice_type) {
            case 'single_image':
              const singleImage = b.primary as TSlideSingleImage;
              if (singleImage.is_fullwidth) {
                return (
                  <div
                    key={b.id}
                    className={cls.slide}
                    data-active={activeSlide === i}
                  >
                    {Math.abs(i - activeSlide) < 4 && (
                      <FullscreenSlide {...(b.primary as TSlideSingleImage)} />
                    )}
                  </div>
                );
              } else {
                return (
                  <div
                    key={b.id}
                    className={cls.slide}
                    data-active={activeSlide === i}
                  >
                    {Math.abs(i - activeSlide) < 4 && (
                      <SingleImageSlide {...(b.primary as TSlideSingleImage)} />
                    )}
                  </div>
                );
              }

            case 'side_by_side_image':
              return (
                <div
                  key={b.id}
                  className={cls.slide}
                  data-active={activeSlide === i}
                >
                  {Math.abs(i - activeSlide) < 4 && (
                    <SideBySideImageSlide
                      {...(b.primary as TSlideSideBySideImage)}
                    />
                  )}
                </div>
              );

            case 'video':
              return (
                <div
                  key={b.id}
                  className={cls.slide}
                  data-active={activeSlide === i}
                >
                  {Math.abs(i - activeSlide) < 4 && (
                    <SlideBlockVideo
                      {...(b.primary as TSlideVideo)}
                      active={activeSlide === i}
                    />
                  )}
                </div>
              );

            default:
              return null;
          }
        })}
        <button className={cls.previous} onClick={previous} />
        <button className={cls.next} onClick={next} />
      </div>
      <div className={cls.footer}>
        {type === 'project' && (
          <ProjectDetailFooter
            cur={convertSliderCounter(activeSlide + 1, lang)}
            total={convertSliderCounter(totalSlides, lang)}
          />
        )}

        {type === 'exploration' && footerSlides && (
          <ExplorationDetailFooter slides={footerSlides} cur={activeSlide} />
        )}
      </div>
    </div>
  );
};

export default DesktopDiaporama;
