import { makeStyles } from 'tss-react/mui';
import stylesheet, {
  blockSpacing,
  bodyLineHeight,
  headerHeight,
} from '../constants/stylesheet';
// import closeCur from 'src/Assets/cursors/close-cursor.cur';
import closePng from '../images/close-cursor.png';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles({ name: 'ProjectInformation' })(() =>
({
  root: {
    position: 'relative',
    height: '100%',

    display: 'flex',

    backgroundColor: 'white',

    '&': {
      cursor: `url(${closePng}),w-resize`,
    },
    // cursor: `url(${closeCur}) 13 13 ,w-resize`,

    [mediaQueries.Mobile]: {
      overflow: 'auto',
      flexDirection: 'column',
    },

    [mediaQueries.AfterMobile]: {
      overflow: 'auto',
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',

    [mediaQueries.Mobile]: {
      flex: `0 0 ${headerHeight.Mobile}`,

      padding: '0 1.5rem',
    },

    [mediaQueries.AfterMobile]: {
      height: headerHeight.AfterMobile,
      flex: '1 1 50%',

      position: 'sticky',
      top: 0,
      paddingLeft: '1.46666rem',
    },
  },

  iconBtn: {
    flex: '0 0 auto',
    lineHeight: 0,

    [mediaQueries.AfterMobile]: {
      padding: '.5rem',
    },
  },

  title: {
    [mediaQueries.Mobile]: {
      margin: '0 auto',
      paddingRight: 15, //compensate btn on the right.
      textAlign: 'center',
    },
    [mediaQueries.AfterMobile]: {
      marginLeft: 'auto',
      marginRight: '1.5rem',
    },
  },

  contentWrapper: {
    [mediaQueries.AfterMobile]: {
      height: '100%',

      flex: '1 1 50%',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  infoWrapper: {
    flex: `0 0 ${headerHeight.AfterMobile}`,

    display: 'flex',
    alignItems: 'center',
    paddingRight: '3.46666rem', // 2 rem to compensate button on the right.

    [mediaQueries.Mobile]: {
      display: 'none',
    },
  },

  info: {
    marginRight: 'auto',
    marginLeft: '1.5rem',
  },

  content: {
    [mediaQueries.AfterMobile]: {
      flex: `1 1 100%`,
      paddingRight: '5rem',
    },
  },

  imagesMobileButton: {
    [mediaQueries.Mobile]: {
      margin: '0 2rem 1rem',
    },
  },

  intro: {
    [mediaQueries.Mobile]: {
      padding: '0 2rem',

      '& p': {
        paddingBottom: bodyLineHeight.Mobile,
      },
    },

    [mediaQueries.AfterMobile]: {
      ...stylesheet.Large,
      paddingBottom: '2rem',
      paddingLeft: '1.5rem',

      '& p': {
        paddingBottom: bodyLineHeight.Desktop,
      },
    },
  },

  informationsItemsFont: {
    ...stylesheet.Infos,
  },

  informationsItems: {
    [mediaQueries.Mobile]: {
      padding: '0 2rem 2rem',

      '&:last-child': {
        paddingBottom: 0,
      },
    },

    [mediaQueries.AfterMobile]: {
      paddingBottom: '5rem',
      transform: 'translateX(-50%)',
    },
  },

  informationsItem: {
    [mediaQueries.Mobile]: {
      paddingBottom: '1.5rem',
    },

    [mediaQueries.AfterMobile]: {
      display: 'flex',
    },
  },

  informationTitle: {
    [mediaQueries.Mobile]: {
      paddingBottom: '.5rem',
    },
    [mediaQueries.AfterMobile]: {
      flex: '1 1 50%',
      textAlign: 'right',
      paddingRight: '1.5rem',
    },
  },

  informationContent: {
    [mediaQueries.AfterMobile]: {
      flex: '1 1 50%',
      textAlign: 'left',
      paddingLeft: '1.5rem',
    },
  }
}));


export default useStyles;
