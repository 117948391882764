import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { TImage } from '../../@types';
import useStyles from './SlideBlockImage.styles';

const SlideBlockImage: FC<TImage & { side?: 'left' | 'right' }> = ({
  gatsbyImageData,
  alt,
  side,
  children,
}) => {
  const {classes: cls} = useStyles();

  if (!gatsbyImageData) {
    return null;
  }

  return (
    <div className={cls.root}>
      <GatsbyImage
        as="figure"
        image={gatsbyImageData}
        alt={alt || ''}
        className={cls.figure}
        objectFit="contain"
        objectPosition={side ? side : undefined}
      />
      {children}
    </div>
  );
};

export default SlideBlockImage;
