import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  TProjectSlides,
  TSlideSideBySideImage,
  TSlideSingleImage,
  TSlideVideo,
  TVideoBlock,
} from '../@types';

export type ProjectSlideFlattened = {
  customId: string;
  alt?: string;
  video?: TVideoBlock;
  image?: IGatsbyImageData;
};

export default (slides: TProjectSlides[]) => {
  return slides
    .filter(({ primary }) => !!primary)
    .reduce((prev, cur) => {
      switch (cur.slice_type) {
        case 'single_image':
          const { single_image } = cur.primary as TSlideSingleImage;
          return [
            ...prev,
            {
              alt: single_image.alt || '',
              image: single_image.gatsbyImageData,
              customId: cur.id,
            },
          ];

        case 'side_by_side_image':
          const { left_image, right_image } =
            cur.primary as TSlideSideBySideImage;
          return [
            ...prev,
            {
              alt: left_image?.alt || '',
              image: left_image.gatsbyImageData,
              customId: `${cur.id}-left`,
            },
            {
              alt: right_image?.alt || '',
              image: right_image.gatsbyImageData,
              customId: `${cur.id}-right`,
            },
          ];

        case 'video':
          const { video } = cur.primary as TSlideVideo;
          return [
            ...prev,
            {
              video,
              customId: cur.id,
            },
          ];

        default:
          return prev;
      }
    }, [] as Array<ProjectSlideFlattened>);
};
