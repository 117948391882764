import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { SlideSingleImage } from '../../@types';
import useStyles from './SingleImageSlide.styles';
import SlideBlockImage from './SlideBlockImage';

const SingleImageSlide: FC<SlideSingleImage> = ({ single_image, children }) => {
  const {classes: cls} = useStyles();

  return (
    <div className={cls.root}>
      <div className={cls.slide}>
        {single_image.gatsbyImageData && <SlideBlockImage {...single_image} />}
      </div>
      {children}
    </div>
  );
};

export default SingleImageSlide;
