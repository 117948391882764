import * as React from 'react';
import { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import { TProjectSlides, TExplorationSlides, TSlideLink } from '../@types';
import useStyles from './Diaporama.styles';
import SingleImageSlide from './Slides/SingleImageSlide';
import ProjectDetailFooter from './ProjectDetailFooter';
import flattenProjectSlides from '../utils/flattenProjectSlides';
import flattenExplorationSlides, {
  ExplorationSlideFlattened,
} from '../utils/flattenExplorationSlides';
import ExplorationDetailFooter from './ExplorationDetailFooter';
import SlideBlockVideo from './Slides/SlideBlockVideo';

const convertSliderCounter = (cur: number, locale: string) =>
  cur.toLocaleString(locale, {
    minimumIntegerDigits: 2,
  });

type Props = {
  lang: string;
  slides: TProjectSlides[] | TExplorationSlides[];
  type: 'project' | 'exploration';
  informationOverlay?: Dispatch<boolean>;
  startingSlideId?: string;
};

const MobileDiaporama: FC<Props> = ({
  slides,
  lang,
  type,
  informationOverlay,
  startingSlideId,
}) => {
  const {classes: cls} = useStyles();
  const [activeSlide, setActiveSlide] = useState(0);

  const slidesFlattened =
    type === 'project'
      ? useMemo(
          () => flattenProjectSlides(slides as TProjectSlides[]),
          [slides]
        )
      : useMemo(
          () => flattenExplorationSlides(slides as TExplorationSlides[]),
          [slides]
        );

  const totalSlides = slidesFlattened.length;

  useEffect(() => {
    if (startingSlideId) {
      const newActive = slidesFlattened.findIndex(
        ({ customId }) => customId === startingSlideId
      );

      setActiveSlide(newActive ? newActive : 0);
    }
  }, [startingSlideId]);

  if (!totalSlides) {
    return null;
  }

  const footerSlides =
    type === 'exploration'
      ? useMemo(
          () =>
            (slidesFlattened as ExplorationSlideFlattened[]).reduce(
              (prev, cur) => {
                const { caption, slide_link } = cur;

                return [...prev, { caption, slide_link }];
              },
              [] as Array<{
                caption?: string | undefined;
                slide_link: TSlideLink;
              }>
            ),
          [slidesFlattened]
        )
      : null;

  const go = (n: number) => setActiveSlide(n);
  const next = () => {
    if (!totalSlides) {
      return;
    }

    go((activeSlide + 1) % totalSlides);
  };
  const previous = () => {
    if (!totalSlides) {
      return;
    }
    const p = activeSlide - 1;
    go(p < 0 ? totalSlides - 1 : p);
  };

  return (
    <div className={cls.root}>
      <div className={cls.slides}>
        {slidesFlattened.map((s, i) => (
          <div
            key={s.customId}
            className={cls.slide}
            data-active={activeSlide === i}
          >
            {s.image && Math.abs(i - activeSlide) < 4 && (
              <SingleImageSlide
                single_image={{ alt: s.alt, gatsbyImageData: s.image }}
              />
            )}
            {s.video && (
              <SlideBlockVideo active={activeSlide === i} video={s.video} />
            )}
          </div>
        ))}
        <button className={cls.previous} onClick={previous} />
        <button className={cls.next} onClick={next} />
      </div>
      <div className={cls.footer}>
        {type === 'project' && (
          <ProjectDetailFooter
            cur={convertSliderCounter(activeSlide + 1, lang)}
            total={convertSliderCounter(totalSlides, lang)}
          >
            {informationOverlay && (
              <button
                className={cls.infoBtn}
                onClick={() => informationOverlay(true)}
              >
                Information
              </button>
            )}
          </ProjectDetailFooter>
        )}
        {type === 'exploration' && footerSlides && (
          <ExplorationDetailFooter slides={footerSlides} cur={activeSlide} />
        )}
      </div>
    </div>
  );
};

export default MobileDiaporama;
