import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { headerHeight } from '../../constants/stylesheet';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = makeStyles({ name: 'SlideBlockVideo' })(() =>
({
  root: {
    position: 'relative',
    height: '100%',

    [mediaQueries.AfterMobile]: {
      margin: '0 5rem',
    },

    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  }
}));

export default useStyles;
