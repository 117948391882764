import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { SlideSingleImage } from '../../@types';
import useStyles from './FullscreenSlide.styles';

const FullscreenSlide: FC<SlideSingleImage> = ({ single_image, children }) => {
  const {classes: cls} = useStyles();
  const { gatsbyImageData, alt } = single_image;

  return (
    <div className={cls.root}>
      {gatsbyImageData && (
        <GatsbyImage
          as="figure"
          image={gatsbyImageData}
          alt={alt || ''}
          objectFit="cover"
          objectPosition="center"
          className={cls.figure}
        />
      )}
      {children}
    </div>
  );
};

export default FullscreenSlide;
