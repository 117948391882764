import React, { FC, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { GlobalStyles } from "tss-react";
import '../styles/reset.css';
import '../styles/typography.css';
import {
  ExplorationPageContext,
  PageContext,
  ProjectPageContext,
} from '../gatsby/createPages';
import WithHeader from './WithHeader';
import WithProjects from './WithProjects';
import WithExplorations from './WithExplorations';
import { makeStyles } from "tss-react/mui";
import stylesheet from '../constants/stylesheet';
import WithPreview from './WithPreview';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import isTouchDevice from 'is-touch-device';
import { I18n } from '../@context/i18n';

const useStyles = makeStyles({ name: 'Layout' })(
  () => ({
    page: {},
    pageEnter: {
      opacity: 0,
    },
    pageEnterActive: {
      opacity: 1,
      transition: 'opacity 500ms',
    },
    pageExit: {
      opacity: 1,
    },
    pageExitActive: {
      opacity: 0,
      transition: 'opacity 500ms',
    },
  })
);

let Layout: FC<Omit<PageProps<any, PageContext>, 'children'>> = ({
  children,
  ...props
}) => {
  const {
    pageContext: { layout, lang },
  } = props;

  const { classes: cls } = useStyles();

  const isTouch = isTouchDevice();
  useEffect(() => {
    if (isTouch) {
      document.body.classList.add('touch-device');
      document.body.classList.remove('no-touch-device');
    } else {
      document.body.classList.remove('touch-device');
      document.body.classList.add('no-touch-device');
    }
  }, [isTouch]);

  const projectProps = props as PageProps<any, ProjectPageContext>;
  const exploratioProps = props as PageProps<any, ExplorationPageContext>;
  const isProject = layout?.match(/^project/);
  const isExploration = layout?.match(/^exploration/);

  return (
    <WithPreview>
      <I18n language={lang}>
        <WithHeader lang={lang}>
          <GlobalStyles
            styles={
              {
                'html, body': {
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  ...stylesheet.Text,
                  overflow: 'hidden',
                },
                a: {
                  color: 'inherit',
                  textDecoration: 'none',
                  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                },
                button: {
                  lineHeight: 'inherit',
                  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                },
                '#___gatsby': {
                  height: '100%',
                  overflow: 'auto',
                  WebkitOverflowScrolling: 'touch',
                },
                'p a': {
                  ...stylesheet.LinkAnim,
                },
              }
            }
          />
          <TransitionGroup>
            <CSSTransition
              timeout={500}
              key={layout}
              classNames={{
                enter: cls.pageEnter,
                enterActive: cls.pageEnterActive,
                exit: cls.pageExit,
                exitActive: cls.pageExitActive,
              }}
            >
              <div className={cls.page} key={layout}>
                {isProject && (
                  <WithProjects {...projectProps}>{children}</WithProjects>
                )}
                {isExploration && (
                  <WithExplorations {...exploratioProps}>
                    {children}
                  </WithExplorations>
                )}
                {!isProject && !isExploration && children}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </WithHeader>
      </I18n>
    </WithPreview>
  );
};
export default Layout;
