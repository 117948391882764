import * as React from 'react';
import { Link } from 'gatsby';
import { FC, useMemo } from 'react';
import useStyles from './ProjectListFilters.styles';
import { getFiltersFromProjects } from '../utils/getFiltersFromNodes';
import { useProjects } from '../layouts/WithProjects';
import { useI18n } from '../@context/i18n';
import { locales } from '../gatsby/createPages';
import { PrismicLangEnum } from '../@types';

const ProjectListFilters: FC<{
  lang: PrismicLangEnum;
  close: React.Dispatch<boolean>;
}> = ({ lang, close }) => {
  const {classes: cls} = useStyles();
  const { projects } = useProjects();
  const { translate } = useI18n();

  const filters = useMemo(() => {
    const all = getFiltersFromProjects(projects).map(
      ({ count, name, uid }) => ({
        count,
        name,
        url: `/${locales[lang]}/projects/${uid}`,
      })
    );

    return [
      {
        count: projects.length,
        name: translate('All'),
        url: `/${locales[lang]}/projects`,
      },
      ...all,
    ] as {
      count: number;
      name: string;
      url: string;
    }[];
  }, [projects]);

  return (
    <div className={cls.root} onClick={() => close(false)}>
      {filters.map(({ name, url, count }, i) => (
        <Link to={url} key={url + i} className={cls.item}>
          {name}
          <span className={cls.sup}>{count}</span>
        </Link>
      ))}
    </div>
  );
};

export default ProjectListFilters;
