import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles<void, 'fade'>({ name: 'ProjectList' })((_theme, _params, classes) => ({
  root: {
    [mediaQueries.Mobile]: {
      marginTop: '2rem',
    },
    [mediaQueries.AfterMobile]: {
      margin: '0 5rem',
      paddingBottom: '5rem',

      display: 'grid',
      gap: `5rem`,
    },
    [mediaQueries.TabletSmallLaptop]: {
      gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    },
    [mediaQueries.Desktop]: {
      gridTemplateColumns: 'repeat(3, minmax(0,1fr))',
    },
    [mediaQueries.BigDesktop]: {
      gridTemplateColumns: 'repeat(4, minmax(0,1fr))',
    },
  },
  item: {
    position: 'relative',

    [mediaQueries.Mobile]: {
      '--padding-top': '0',
      '--padding-bottom': '4rem',
      '--padding-sides': '2rem',
      '--adicional-padding-bottom': '0',

      padding:
        'var(--padding-top) var(--padding-sides) var(--padding-bottom)',
      marginBottom: 'var(--adicional-padding-bottom)',
    },
    [mediaQueries.AfterMobile]: {
      height: 0,
      paddingBottom: '100%',

      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',

      opacity: 1,
      willChange: 'opacity',
      transition: 'opacity .4s ease',

      [`.${classes.fade}&`]: {
        opacity: 0.3,
      },
    },
  },
  // figure: {
  //   [mediaQueries.AfterMobile]: {
  //     position: 'absolute',
  //     '[data-format="landscape"]&': {
  //       left: 0,
  //       right: 0,
  //     },

  //     '[data-format="portrait"]&': {
  //       top: 0,
  //       bottom: 0,
  //     },

  //     '[data-format="square"]&': {
  //       inset: 0,
  //     },
  //   },
  // },
  // imageWrapper: {
  //   position: 'absolute',
  //   inset: 0,

  //   '& img': {
  //     position: 'absolute',
  //     inset: 0,
  //   },
  // },
  // mainLink: {
  //   position: 'absolute',
  //   inset: 0,
  //   zIndex: 1,
  // },
  // image: {
  //   position: 'absolute',
  //   inset: 0,
  // },
  // figcaption: {
  //   textAlign: 'center',
  //   paddingTop: '0.6666rem',

  //   [mediaQueries.AfterMobile]: {
  //     position: 'absolute',
  //     top: '100%',
  //     left: 0,
  //     width: '100%',

  //     opacity: 0,
  //     willChange: 'opacity',
  //     transition: 'opacity .4s ease',

  //     '$item:hover &': {
  //       opacity: 1,
  //     },
  //   },
  // },
  fade: {},
}));

export default useStyles;
