import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import { useI18n } from '../@context/i18n';
import { useResponsive } from '../@context/Responsive';
import { locales } from '../gatsby/createPages';
import { useExplorations } from '../layouts/WithExplorations';
import useStyles from './Explorations.styles';

const convertToTwoDigit = (n: number, l: string) =>
  n.toLocaleString(l, {
    minimumIntegerDigits: 2,
  });

const Explorations: FC<{}> = ({}) => {
  const {classes: cls} = useStyles();
  const [hovering, setHovering] = useState<string>();
  const { explorations } = useExplorations();
  const { steps } = useResponsive();
  const { translate } = useI18n();

  return (
    <div className={cls.root}>
      {explorations.map(({ data, id, lang, uid }) => {
        const { title, number, introduction } = data;

        return (
          <article
            key={id}
            className={cls.item}
            data-hide={!!(hovering && hovering !== id)}
          >
            <h4
              className={cls.title}
              onMouseOver={() => setHovering(id)}
              onMouseLeave={() => setHovering('')}
            >
              <span className={cls.number}>
                {convertToTwoDigit(number || 0, lang)}
              </span>
              {steps.includes('Mobile') && title?.text}
              {steps.includes('AfterMobile') && (
                <Link className={cls.link} to={`/${locales[lang]}/transformation/${uid}`}>
                  {title?.text}
                </Link>
              )}
            </h4>
            <div className={cls.text}>{introduction}</div>
            <Link
              className={cls.linkMobile}
              to={`/${locales[lang]}/transformation/${uid}`}
            >
              {translate('SeeImages')}
            </Link>
          </article>
        );
      })}
    </div>
  );
};

export default Explorations;
