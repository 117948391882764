import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import useStyles from './ProjectList.styles';
import classNames from 'classnames';
import Thumbnail from './Thumbnail';
import { useResponsive } from '../@context/Responsive';
import ImageBlock from './ImageBlock';
import { useProjects } from '../layouts/WithProjects';
import { locales } from '../gatsby/createPages';

const ProjectList: FC = ({ children }) => {
  const { classes: cls } = useStyles();
  const { steps } = useResponsive();
  const { projects, category } = useProjects();
  const [itemHovered, setItemHovered] = useState('');

  const all = useMemo(
    () =>
      projects.filter((p) =>
        p.data.categories?.find(
          (c) => !category || c.category?.document?.id === category.id
        )
      ),
    [projects, category]
  );

  if (steps.includes('Mobile')) {
    return (
      <div className={cls.root}>
        {all.map(
          ({ data: { thumbnail, title }, id, lang, uid }) =>
            thumbnail && (
              <article className={cls.item}>
                <ImageBlock
                  key={id}
                  link={{ url: `/${locales[lang]}/projects/${uid}` }}
                  image={thumbnail}
                  caption={title?.text ?? ''}
                />
              </article>
            )
        )}
      </div>
    );
  }

  return (
    <div className={cls.root}>
      {all
        .filter(({ data: { thumbnail } }) => thumbnail)
        .map(({ id, lang, uid, data: { title, thumbnail } }) => (
          <article
            className={classNames(
              cls.item,
              itemHovered && itemHovered !== id && cls.fade
            )}
            key={id}
          >
            <Thumbnail
              image={thumbnail!.gatsbyImageData}
              handleHover={setItemHovered}
              id={id}
              link={`/${locales[lang]}/projects/${uid}`}
              title={title?.text}
              alt={thumbnail?.alt ?? ''}
            />
          </article>
        ))}
      {children}
    </div>
  );
};

export default React.memo(ProjectList);
