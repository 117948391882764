import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';
import stylesheet, { headerHeight } from '../constants/stylesheet';

const useStyles = makeStyles({ name: 'ExplorationOverview' })(() =>
({
  root: {
    position: 'relative',
    height: '100%',

    display: 'flex',

    backgroundColor: 'white',
    overflow: 'auto',

    [mediaQueries.Mobile]: {
      overflow: 'auto',
      flexDirection: 'column',
    },

    [mediaQueries.AfterMobile]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  header: {
    position: 'sticky',
    top: 0,

    display: 'flex',
    alignItems: 'center',

    zIndex: 1,

    [mediaQueries.Mobile]: {
      flex: `0 0 ${headerHeight.Mobile}`,
      padding: '0 1.5rem',
    },

    [mediaQueries.AfterMobile]: {
      flex: `0 0 ${headerHeight.AfterMobile}`,
      padding: '0 1.46666rem',
    },
  },

  iconBtn: {
    flex: '0 0 auto',
    lineHeight: 0,

    [mediaQueries.AfterMobile]: {
      padding: '.5rem',
    },
  },

  titleWrapper: {
    flex: '1 1 100%',
  },

  title: {
    textTransform: 'uppercase',
    textAlign: 'center',

    [mediaQueries.Mobile]: {
      paddingRight: 17,
    },

    [mediaQueries.AfterMobile]: {
      paddingRight: '2rem',
    },
  },

  content: {
    flex: '1 1 100%',
    display: 'grid',

    [mediaQueries.Mobile]: {
      margin: '0 2rem 2rem',

      gap: `2rem`,
      gridTemplateColumns: 'repeat(2, minmax(0,1fr))',
    },
    [mediaQueries.AfterMobile]: {
      margin: '0 5rem 5rem',

      gap: `5rem`,
      gridTemplateColumns: 'repeat(4, minmax(0,1fr))',
    },
  },

  item: {
    position: 'relative',
    height: 0,
    paddingBottom: '100%',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
  }
}));

export default useStyles;
