import { makeStyles } from 'tss-react/mui';
import stylesheet, { blockSpacing } from '../constants/stylesheet';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles({ name: 'ProjectDetailFooter' })(() =>
({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    [mediaQueries.AfterMobile]: {
      ...stylesheet.Title,
    },
  },

  counter: {
    WebkitFontFeatureSettings: '"tnum"',
    MozFontFeatureSettings: '"tnum"',
    fontFeatureSettings: '"tnum"',

    [mediaQueries.Mobile]: {
      flex: '1 1 50%',
      padding: '0 1rem 0 2rem',
      textAlign: 'right',
    },
  }
}));


export default useStyles;
