import { ProjectCategory, ProjectListItem } from '../@types';

export const getFiltersFromProjects = (nodes: ProjectListItem[]) => {
  const filtersDic = getCategories(nodes);
  return Object.values(filtersDic).map(({ uid, data: { name }, count }) => ({
    uid: uid || '',
    count,
    name: name?.text || '',
  }));
};

export const getCategories = (nodes: ProjectListItem[]) => {
  return nodes
    .filter((n) => n.data && n.data.categories?.length)
    .reduce((agg, n) => {
      const docs = n.data.categories?.map((cat) => cat.category?.document);
      docs?.forEach((doc) => {
        if (!doc) return;
        agg[doc.id] = agg[doc.id] || { ...doc, count: 0 };
        const count = agg[doc.id].count;
        agg[doc.id].count = count + 1;
      });
      return agg;
    }, {} as Record<string, ProjectCategory & { count: number }>);
};
