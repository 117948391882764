export const mobileSize = 764;
export const tabletSamllLaptopSize = 1364;
export const desktopSize = 1999;

const mediaQueries = {
  Mobile: `@media screen and (max-width: ${mobileSize}px)`,
  AfterMobile: `@media screen and (min-width: ${mobileSize + 1}px)`,
  TabletSmallLaptop: `@media screen and (min-width: ${
    mobileSize + 1
  }px) and (max-width: ${tabletSamllLaptopSize}px)`,
  Desktop: `@media screen and (min-width: ${
    tabletSamllLaptopSize + 1
  }px) and (max-width: ${desktopSize}px)`,
  BigDesktop: `@media screen and (min-width: ${desktopSize + 1}px)`,
};

export default mediaQueries;
