import { Link } from 'gatsby';
import React, { FC } from 'react';
import { useResponsive } from '../@context/Responsive';
import { TThumbnailExploration } from '../@types';
import { locales } from '../gatsby/createPages';
import useStyles from './ExplorationOverview.styles';
import Close from './Icons/Close';
import Thumbnail from './Thumbnail';

type Props = {
  title: string;
  items: Array<TThumbnailExploration>;
  lang: string;
  goTo: (id: string) => void;
};
const ExplorationOverview: FC<Props> = ({
  title,
  items,
  goTo,
  lang,
  children,
}) => {
  const {classes: cls} = useStyles();
  const { steps } = useResponsive();

  return (
    <section className={cls.root}>
      <header className={cls.header}>
        <Link to={`/${locales[lang]}/transformation`} className={cls.iconBtn}>
          <Close />
        </Link>
        <div className={cls.titleWrapper}>
          <h6 className={cls.title}>{title}</h6>
        </div>
      </header>
      <div className={cls.content}>
        {items
          .filter(({ image }) => image)
          .map(({ image, alt, id, customId }) => {
            return (
              <article className={cls.item} key={customId}>
                <Thumbnail
                  image={image!}
                  id={steps.includes('Mobile') ? customId : id}
                  handleClick={goTo}
                  alt={alt || ''}
                />
              </article>
            );
          })}
      </div>
      {children}
    </section>
  );
};

export default ExplorationOverview;
