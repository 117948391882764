import * as React from 'react';
import { FC, useMemo } from 'react';
import useStyles from './ImageBlock.styles';
import CustomLink from './CustomLink';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

type TImageBlock = {
  caption?: string;
  image: {
    gatsbyImageData?: IGatsbyImageData;
    alt?: string;
  };
  link?: { url: string };
};

const ImageBlock: FC<TImageBlock> = ({ children, image, link, caption }) => {
  const {classes: cls} = useStyles();
  const { gatsbyImageData, alt } = image;

  if (!gatsbyImageData || !gatsbyImageData.height) {
    return null;
  }

  const ratio = useMemo(
    () => gatsbyImageData.width / gatsbyImageData.height,
    [gatsbyImageData]
  );

  return (
    <>
      <div className={cls.figureContainer}>
        <div style={{ paddingBottom: (1 / ratio) * 100 + '%', height: 0 }} />
        <GatsbyImage
          image={gatsbyImageData}
          className={cls.image}
          alt={alt || ''}
          objectFit="contain"
        />
        {link && <CustomLink link={link.url} />}
      </div>
      <div className={cls.caption}>{caption}</div>
      {children}
    </>
  );
};

export default ImageBlock;
