import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';
import { headerHeight } from '../constants/stylesheet';
// import previousCur from 'src/Assets/cursors/arrow-left.cur';
import previousPng from '../images/prev-cursor.png';
// import nextCur from 'src/Assets/cursors/arrow-right.cur';
import nextPng from '../images/next-cursor.png';

const useStyles = makeStyles({ name: 'Diaporama' })(() =>
  ({
    root: {
      height: '100%',

      display: 'flex',
      flexDirection: 'column',
    },

    slides: {
      position: 'relative',
      flex: '1 1 100%',
    },

    slide: {
      position: 'absolute',

      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .4s ease',

      '[data-active="true"]&': {
        opacity: 1,
      },

      [mediaQueries.Mobile]: {
        inset: '0 2rem',
      },
      [mediaQueries.AfterMobile]: {
        inset: '0',
      },
    },

    previous: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%',
      height: '100%',

      outline: 'transparent',

      '&': {
        cursor: `url(${previousPng}),w-resize`,
      },
      // cursor: `url(${previousCur}) 13 13 ,w-resize`,
    },

    next: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '50%',
      height: '100%',

      '&': {
        cursor: `url(${nextPng}),e-resize`,
      },
      // cursor: `url(${nextCur}) 13 13 ,e-resize`,
    },

    footer: {
      position: 'relative',

      zIndex: 1,
      [mediaQueries.Mobile]: {
        flex: `0 0 ${headerHeight.Mobile}`,
      },

      [mediaQueries.AfterMobile]: {
        flex: `0 0 ${headerHeight.AfterMobile}`,
      },
    },

    infoBtn: {
      flex: '1 1 50%',
      padding: '0 2rem 0 1rem',
      textAlign: 'left',
    }
  }));
export default useStyles;
