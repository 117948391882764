import type { LinkResolverFunction } from '@prismicio/helpers';
import { PrismicLangEnum } from '../@types';
import { locales } from '../gatsby/createPages';

export const linkResolver: LinkResolverFunction = (doc) => {
  const lang = (doc?.lang || Object.keys(locales)[0]) as PrismicLangEnum;

  // URL for a home type
  if (doc.type === 'homepage') {
    return `/${locales[lang]}`;
  }

  // URL for a atelier type
  if (doc.type === 'atelier') {
    return `/${locales[lang]}/atelier`;
  }

  // URL for a product type
  if (doc.type === 'projectOverview' || doc.type === 'project_overview') {
    return `/${locales[lang]}/projects`;
  }

  // URL for a product type
  if (doc.type === 'projectCategory' || doc.type === 'project_category') {
    return `/${locales[lang]}/projects/${doc.uid}`;
  }

  // URL for a product type
  if (doc.type === 'project') {
    return `/${locales[lang]}/projects/${doc.uid}`;
  }

  // URL for a product type
  if (doc.type === 'exploration') {
    return `/${locales[lang]}/transformation/${doc.uid}`;
  }

  // Fallback for all other types
  return `/${locales[lang]}`;
};
export default linkResolver;
