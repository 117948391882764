import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';
import stylesheet, { headerHeight } from '../constants/stylesheet';

const useStyles = makeStyles({ name: 'ExplorationDetailFooter' })(() =>
({
  root: {
    position: 'relative',
    height: '100%',

    [mediaQueries.Mobile]: {
      margin: '0 2rem',
    },

    [mediaQueries.AfterMobile]: {
      margin: '0 5rem',
    },
  },

  explorationsLegend: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',

    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity .4s ease',
    willChange: 'opacity',

    textAlign: 'center',

    '&[data-active="true"]': {
      opacity: 1,
      pointerEvents: 'initial',
    },

    '.no-touch-device &': {
      ...stylesheet.LinkAnim,
    },
  },

  explorationsLegendLink: {
    position: 'absolute',
    inset: 0,
  }
}));

export default useStyles;
