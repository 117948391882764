import React from 'react';
import { makeStyles } from "tss-react/mui";
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles({ name: 'WithProjects' })(
  () => ({
      content: {
        [mediaQueries.Mobile]: {
          paddingBottom: '4rem',
        },
      },
      page: {
        position: 'fixed',
        inset: 0,

        zIndex: 10,
      },
      pageEnter: {
        opacity: 0,
      },
      pageEnterActive: {
        opacity: 1,
        transition: 'opacity 500ms',
      },
      pageExit: {
        opacity: 1,
      },
      pageExitActive: {
        opacity: 0,
        transition: 'opacity 500ms',
      },
      aside: {
        position: 'fixed',
        zIndex: 1,

        [mediaQueries.Mobile]: {
          bottom: 0,
          left: 0,
          width: '100%',
          height: '4rem',
        },

        [mediaQueries.AfterMobile]: {
          top: 0,
          right: 0,
          height: '100vh',
          width: '5rem',
        },
      },
      asideTitle: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: '50%',
        textAlign: 'center',

        [mediaQueries.Mobile]: {
          transform: 'translateY(-50%)',
        },

        [mediaQueries.AfterMobile]: {
          transform: 'translateY(-50%) rotate(90deg)',
        },
      },
      filtersWrapper: {
        position: 'fixed',
        inset: 0,

        backgroundColor: 'rgba(255,255,255, .8)',
      },
    })
);
export default useStyles;
