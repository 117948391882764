import * as React from 'react';
import { FC, useContext, useState, useCallback } from 'react';

type Responsive = {
	steps: string[];
};

export const ResponsiveContext = React.createContext<Responsive>({} as never);

type ResponsiveProviderProps = {
	mediaQueries: { [key: string]: string };
};
export const ResponsiveProvider: FC<ResponsiveProviderProps> = ({
	mediaQueries,
	children,
}) => {
	const [steps, setSteps] = useState<Responsive['steps']>([]);

	const onResize = useCallback(() => {
		setSteps(
			Object.keys(mediaQueries).filter(
				(m) => window.matchMedia(mediaQueries[m].replace('@media ', '')).matches
			)
		);
	}, [mediaQueries, setSteps]);

	React.useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [onResize]);

	return (
		<ResponsiveContext.Provider value={{ steps }}>
			{children}
		</ResponsiveContext.Provider>
	);
};
export const Responsive: FC<{ steps: string[] }> = ({ steps, children }) => {
	// eslint-disable-next-line no-use-before-define
	const { steps: CurrentSteps } = useResponsive();
	if (steps.filter((x) => CurrentSteps.includes(x)).length) {
		return <>{children}</>;
	}
	return null;
};
export const useResponsive = () => useContext(ResponsiveContext);
