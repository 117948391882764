import { Link } from 'gatsby';
import React, { useCallback, useEffect, useRef } from 'react';
import useStyles from './Header.styles';
import isTouchDevice from 'is-touch-device';

const Header: React.FC<{ links: { title: string; url: string }[] }> = ({
  links,
  children,
}) => {
  const {classes: cls} = useStyles();
  const headerRef = useRef<HTMLElement>(null);
  const lastScrollTop = useRef(0);

  const handleScroll = useCallback((ev: Event) => {
    const header = headerRef.current;
    if (!header || !ev.currentTarget) return;

    const { scrollTop } = ev.currentTarget as Element;

    if (scrollTop > lastScrollTop.current) {
      header.setAttribute('data-scrolling', 'down');
    } else {
      header.setAttribute('data-scrolling', 'up');
    }
    lastScrollTop.current = scrollTop;

    if (scrollTop > 0) {
      header.setAttribute('data-scrolled', 'true');
    } else {
      header.setAttribute('data-scrolled', 'false');
    }
  }, []);

  useEffect(() => {
    const scrollContainer = document?.getElementById('___gatsby');
    if (!scrollContainer) {
      return;
    }
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={cls.root} ref={headerRef} data-scrolled="false">
      <nav className={cls.nav}>
        {links.map(({ title, url }, i) => (
          <Link key={url + i} to={url} className={cls.menuItem}>
            {title}
          </Link>
        ))}
      </nav>
      {!isTouchDevice() && (
        <div className={cls.logo}>Atelier VILLEMARD ASSOCIÉS</div>
      )}
      {children}
    </header>
  );
};

export default Header;
