import { makeStyles } from 'tss-react/mui';
import {
  homeBlockMaxWidth,
} from '../constants/stylesheet';

const useStyles = makeStyles({ name: 'ImageBlock' })(() =>
({
  // root: {
  //   position: 'relative',
  //   padding:
  //     'var(--padding-top) var(--padding-sides) var(--padding-bottom)',
  //   marginBottom: 'var(--adicional-padding-bottom)',

  //   [mediaQueries.Mobile]: {
  //     '--padding-top': '0',
  //     '--padding-bottom': '4rem',
  //     '--padding-sides': '2rem',
  //     '--adicional-padding-bottom': '0',
  //   },
  //   [mediaQueries.AfterMobile]: {
  //     '--padding-top': '6rem',
  //     '--padding-bottom': '5.33333rem',
  //     '--padding-sides': '5rem',
  //     '--adicional-padding-bottom': '5rem', // https://github.com/Romain-Preston-Unipessoal-Lda/villemardassocies.fr/issues/50
  //   },
  // },
  figureContainer: {
    position: 'relative',
    overflow: 'hidden',
    maxHeight: '75vh',

    // '&:first-of-type': {
    //   maxHeight:
    //     'calc(100vh - var(--padding-bottom) - var(--padding-bottom)',
    // },
  },

  image: {
    "&.gatsby-image-wrapper": {
      position: 'absolute',
      inset: 0,
    }
  },

  // rootFullScreenFormat: {
  //   [mediaQueries.Mobile]: {
  //     padding: blockSpacing.Mobile,
  //     marginBottom: '2rem',
  //   },
  //   [mediaQueries.AfterMobile]: {
  //     margin: '6rem 0 5.33333rem',
  //   },
  // },
  // fullwidtFigure: {
  //   position: 'relative',
  // },
  // fullwidtImage: {
  //   display: 'block',
  //   width: '100%',
  //   height: 'auto',
  // },
  // anim: {
  //   '&:not(:first-of-type)': {
  //     ...stylesheet.Anim,
  //   },
  // },
  caption: {
    paddingTop: '.5rem',
    textAlign: 'center',
    maxWidth: homeBlockMaxWidth,
    margin: '0 auto',
  }
}));

export default useStyles;
