import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { TProjectDetail } from '../@types';
import { Link } from 'gatsby';
import useStyles from './Detail.styles';
import Close from './Icons/Close';
import DesktopDiaporama from './DesktopDiaporama';
import MobileDiaporama from './MobileDiaporama';
import ProjectInformation from './ProjectInformation';
import { useResponsive } from '../@context/Responsive';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { navigate } from 'gatsby';
import { useI18n } from '../@context/i18n';
import SEO from './Seo';
import { locales } from '../gatsby/createPages';

const ProjectDetail: FC<{ project?: TProjectDetail }> = ({ project }) => {
  const {classes: cls} = useStyles();
  const [showInfo, setShowInfo] = useState(false);
  const [showIntro, setShowIntro] = useState(
    !!project?.data?.intro_title?.text
  );
  const { steps } = useResponsive();
  const { translate } = useI18n();

  const removeIntro = useCallback(() => {
    setShowIntro(false);
  }, []);

  useEffect(() => {
    if (!showIntro) {
      return;
    }
    const timeOut = setTimeout(removeIntro, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [removeIntro]);

  const handleKeyPress = useCallback(
    (key: KeyboardEvent) => {
      switch (key.keyCode) {
        case 27: // esc
          if (showInfo) {
            setShowInfo(false);
          } else {
            navigate(`/${locales[project!.lang]}/projects`);
          }
          break;

        default:
          break;
      }
    },
    [setShowInfo, navigate, showInfo]
  );

  useEffect(() => {
    if (!project) {
      return;
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (!project) {
    return null;
  }

  const {
    intro_title,
    title,
    body,
    informations,
    introduction,
    seo_title,
    seo_description,
    seo_image,
  } = project.data;

  return (
    <>
      <SEO
        title={seo_title ?? ''}
        description={seo_description ?? ''}
        image={seo_image?.fixed?.src || ''}
      />
      <section className={cls.root} data-type="project">
        <CSSTransition
          in={showIntro}
          timeout={500}
          classNames={{
            // enter: cls.projectIntroEnter,
            // enterActive: cls.projectIntroEnterActive,
            exit: cls.projectIntroExit,
            exitActive: cls.projectIntroExitActive,
          }}
          unmountOnExit
        >
          <div
            className={classNames(cls.projectIntro, cls.projectIntroFont)}
            data-show-intro={showIntro}
          >
            {intro_title?.text}
          </div>
        </CSSTransition>
        <header className={cls.header}>
          <Link to={`/${locales[project.lang]}/projects`} className={cls.iconBtn}>
            <Close />
          </Link>
          <div className={cls.headerContent}>
            <h2 className={cls.title}>{title?.text}</h2>
            <button
              className={cls.headerInfoBtn}
              onClick={() => setShowInfo(true)}
            >
              {translate('Informations')}
            </button>
          </div>
        </header>
        <article className={cls.content}>
          {body && steps.includes('AfterMobile') && (
            <DesktopDiaporama
              slides={body}
              lang={project.lang}
              type="project"
            />
          )}
          {body && steps.includes('Mobile') && (
            <MobileDiaporama
              slides={body}
              lang={project.lang}
              type="project"
              informationOverlay={(v: boolean) => setShowInfo(v)}
            />
          )}
        </article>
        <div className={cls.overlay} data-show={showInfo}>
          <ProjectInformation
            title={title}
            informations={informations}
            introduction={introduction}
            close={(v: boolean) => setShowInfo(v)}
            lang={project.lang}
          />
        </div>
      </section>
    </>
  );
};

export default ProjectDetail;
