import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { LangEnum, PrismicLangEnum } from '../@types';
import { locales } from '../gatsby/createPages';

type Ti18n = {
  translate: (key: string) => string;
};
// const languages = ['fr-fr', 'en-eu'];
export const I18nContext = React.createContext<Ti18n>({} as never);

type Props = { language: PrismicLangEnum };
export const I18n: React.FC<Props> = ({ children, language }) => {
  const [dict, setDict] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const lang = locales[language] ?? 'fr';
    (async () => {
      const result = await import(`../i18n/${lang}.json`);
      setDict(result);
    })();
  }, [language]);

  return (
    <I18nContext.Provider
      value={{ translate: (key) => (dict && dict[key]) || key }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => useContext(I18nContext);
