import { Link } from 'gatsby';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import React, { Dispatch, FC } from 'react';
import useStyles from './Thumbnail.styles';

const Thumbnail: FC<{
  image?: IGatsbyImageData;
  id: string;
  alt: string;
  title?: string;
  link?: string;
  handleHover?: Dispatch<string>;
  handleClick?: (id: string) => void;
}> = ({ image, id, handleHover, link, title, handleClick, alt }) => {
  const {classes: cls} = useStyles();

  const ratio = image ? image.height / image.width : 0;
  const format = ratio === 1 ? 'square' : ratio < 1 ? 'landscape' : 'portrait';
  const propertyContrain =
    format === 'portrait' ? 'width' : format === 'landscape' ? 'height' : '';
  const valueConstrain =
    format === 'portrait' ? (1 / ratio) * 100 : ratio * 100;

  const handleMouseHover =
    handleHover && id ? () => handleHover(id) : undefined;
  const handleLeaveHover =
    handleHover && id ? () => handleHover('') : undefined;

  return (
    <div
      className={cls.root}
      style={
        format === 'square'
          ? undefined
          : { [propertyContrain]: `${valueConstrain}%` }
      }
      data-format={format}
      onMouseOver={handleMouseHover}
      onMouseLeave={handleLeaveHover}
    >
      {image && <GatsbyImage
        image={image}
        alt={alt}
        className={cls.figure}
        as="figure"
        objectFit="cover"
        objectPosition="center"
      />}
      {link && !handleClick && (
        <>
          <div className={cls.legend}>
            <Link to={link}>{title || ''}</Link>
          </div>
          <Link to={link} className={cls.mainLink} />
        </>
      )}
      {!link && handleClick && (
        <button
          className={cls.thumbnailBtn}
          onClick={() => {
            handleClick(id);
          }}
        />
      )}
    </div>
  );
};

export default React.memo(Thumbnail);
