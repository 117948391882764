import React, { FC } from 'react';
import { makeStyles } from "tss-react/mui";
import Header from '../components/Header';
import { locales } from '../gatsby/createPages';
import { useI18n } from '../@context/i18n';
import { PrismicLangEnum } from '../@types';

const useStyles = makeStyles({ name: 'WithHeader' })(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      flex: 0,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    content: {
      position: 'relative',
    },
  })
);

const WithHeader: FC<{ lang: PrismicLangEnum }> = ({ lang, children }) => {
  const { classes: cls } = useStyles();
  const { translate } = useI18n();
  lang = lang || Object.keys(locales)[0];
  const links = [
    { title: translate('Home'), url: `/${locales[lang]}` },
    { title: translate('Projects'), url: `/${locales[lang]}/projects` },
    {
      title: translate('Explorations'),
      url: `/${locales[lang]}/transformation`,
    },
    { title: translate('Atelier'), url: `/${locales[lang]}/atelier` },
  ];

  return (
    <div className={cls.root}>
      <div className={cls.header}>
        <Header links={links} />
      </div>
      <main className={cls.content}>{children}</main>
    </div>
  );
};

export default WithHeader;
