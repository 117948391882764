import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = makeStyles({ name: 'SingleImageSlide' })(() =>
({
  root: {
    height: '100%',
    [mediaQueries.AfterMobile]: {
      margin: '0 5rem',
    },
  },

  slide: {
    height: '100%',
    width: '100%',
  }
}));

export default useStyles;
