import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const WithPreviewHeader: FC<unknown> = ({ children }) => {
  return <>
    <Helmet encodeSpecialCharacters={false}>
      <script
        async
        defer
        src={`https://static.cdn.prismic.io/prismic.js?repo=${process.env.GATSBY_PRISMIC_REPO}&new=true`}
      ></script>
    </Helmet>
    {children}
  </>;
}

const WithPreview: FC<unknown> = ({ children }) => {
  return process.env.GATSBY_PRISMIC_PREVIEW === 'true' ? <WithPreviewHeader>{children}</WithPreviewHeader> : <>{children}</>;
};
export default WithPreview;