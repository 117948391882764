import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';
import stylesheet from '../constants/stylesheet';

const useStyles = makeStyles<void, 'title'>({ name: 'Explorations' })(() => ({
  root: {
    [mediaQueries.AfterMobile]: {
      padding: '0 5rem 5rem',
    },
  },
  item: {
    [mediaQueries.Mobile]: {
      padding: '2rem',

      '&:last-child': {
        paddingBottom: 0,
      },
    },
    [mediaQueries.AfterMobile]: {
      display: 'inline',

      opacity: 1,
      willChange: 'opacity',
      transition: 'opacity .4s ease',

      '[data-hide="true"]&': {
        opacity: 0,
      },
      '&:hover div': {
        opacity: 1,
      },

    },
  },
  link: {
    [mediaQueries.Mobile]: {
      display: 'none',
    },
  },
  linkMobile: {
    textAlign: 'center',
    display: 'block',

    [mediaQueries.AfterMobile]: {
      display: 'none',
    },
  },
  number: {
    WebkitFontFeatureSettings: '"frac"',
    MozFontFeatureSettings: '"frac"',
    '-ms-font-feature-settings': '"frac"',
    fontFeatureSettings: '"frac"',

    [mediaQueries.AfterMobile]: {
      '&:before': {
        content: '" "',
      },
    },
  },
  title: {
    textTransform: 'uppercase',
    [mediaQueries.Mobile]: {
      textAlign: 'center',
      ...stylesheet.Display,

      marginBottom: '1rem',
    },

    [mediaQueries.AfterMobile]: {
      position: 'relative',
      display: 'inline',

      ...stylesheet.Title,

      '&:after': {
        content: '" "',
      },
    },
  },
  text: {
    [mediaQueries.Mobile]: {
      ...stylesheet.Large,

      marginBottom: '1rem',
    },
    [mediaQueries.AfterMobile]: {
      display: 'inline',

      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .4s ease',
      ...stylesheet.Title,


      '.touch-device &': {
        opacity: 0.25,
      },
    },
  },
}));
export default useStyles;
