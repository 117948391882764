import React, { FC } from 'react';
import useStyles from './ProjectDetailFooter.styles';

const ProjectDetailFooter: FC<{ total: string; cur: string }> = ({
  cur,
  total,
  children,
}) => {
  const {classes: cls} = useStyles();
  return (
    <div className={cls.root}>
      <div className={cls.counter}>
        {cur} / {total}
      </div>
      {children}
    </div>
  );
};

export default ProjectDetailFooter;
