import * as React from 'react'
import { CacheProvider } from '@emotion/react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './src/utils/linkResolver';
import { makeMuiCache } from './src/theme/cache';

const cache = makeMuiCache();

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      page: React.lazy(() => import('./src/layouts/index')),
    }
  }
];

export const wrapRootElement = ({ element }) => (
  process.env.GATSBY_PRISMIC_PREVIEW === 'true' ?
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <CacheProvider value={cache}>{element}</CacheProvider>
    </PrismicPreviewProvider> :
    <CacheProvider value={cache}>{element}</CacheProvider>
)

const isProject = (...locations) => locations.every(l => l?.pathname?.match(/^[^/]+\/project/));
const isExploration = (...locations) => locations.every(l => l?.pathname?.match(/^[^/]+\/exploration/));
export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
}) => {
  const { location: prevLocation } = prevRouterProps || {};
  const { location: curLocation } = routerProps || {};
  // const currentPosition = getSavedScrollPosition(curLocation)

  if (isProject(prevLocation, curLocation) || isExploration(prevLocation, curLocation)) {
    return false;
  }

  document.getElementById('___gatsby').scrollTo(0, 0)
}