import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles<void, 'root'>({ name: 'Thumbnail' })((_theme, _params, classes) => ({
  root: {
    '[data-format="landscape"]&': {
      position: 'absolute',
      left: 0,
      right: 0,
    },
    '[data-format="portrait"]&': {
      position: 'absolute',
      top: 0,
      bottom: 0,
    },
    '[data-format="square"]&': {
      position: 'absolute',
      inset: 0,
    },
  },
  figure: {
    position: 'absolute',
    inset: 0,

    '& img': {
      position: 'absolute',
      inset: 0,
      width: '100%',
      height: '100%',
    },
  },
  mainLink: {
    position: 'absolute',
    inset: 0,
    zIndex: 1,
  },
  legend: {
    textAlign: 'center',
    paddingTop: '0.6666rem',

    [mediaQueries.AfterMobile]: {
      position: 'absolute',
      top: '100%',
      left: 0,
      width: '100%',

      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .4s ease',

      [`.${classes.root}:hover &`]: {
        opacity: 1,
      },

      '.touch-device &': {
        opacity: 1,
      },
    },
  },
  thumbnailBtn: {
    position: 'absolute',
    inset: 0,
    width: '100%',
  },
}));

export default useStyles;
