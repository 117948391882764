import * as React from 'react';
import { PageProps } from 'gatsby';
import { createContext, FC, useCallback, useContext } from 'react';
import ProjectList from '../components/ProjectList';
import ProjectListFilters from '../components/ProjectListFilters';
import type { ProjectPageContext } from '../gatsby/createPages';
import { useState } from 'react';
import useStyles from './WithProjects.styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ResponsiveProvider } from '../@context/Responsive';
import mediaQueries from '../constants/mediaQueries';
import { ProjectCategory, ProjectListItem, TProjectDetail } from '../@types';
import ProjectDetail from '../components/ProjectDetail';
import { useI18n } from '../@context/i18n';

type TProjectContext = {
  updateProjects: (projects: ProjectListItem[]) => void;
  projects: ProjectListItem[];
  updateProject: (project?: TProjectDetail) => void;
  project?: TProjectDetail;
  updateCategory: (category?: ProjectCategory) => void;
  category?: ProjectCategory;
};

const ProjectContext = createContext<TProjectContext>({} as never);

ProjectContext.displayName = 'ProjectContext';
export const useProjects = () => useContext(ProjectContext);

export const WithProjects: FC<
  Omit<PageProps<unknown, ProjectPageContext>, 'children'>
> = ({ pageContext: { projectId, lang }, children }) => {
  const {classes: cls} = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const [category, setCategory] = useState<ProjectCategory>();
  const [projects, setProjects] = useState<ProjectListItem[]>([]);
  const [project, setProject] = useState<TProjectDetail>();
  const { translate } = useI18n();

  const updateProjects = useCallback(
    (items: ProjectListItem[]) => {
      if (
        items?.length &&
        projects.map((p) => p.id).join(',') != items.map((p) => p.id).join(',')
      ) {
        setProjects(items);
      }
    },
    [projects]
  );

  const updateProject = useCallback(
    (item?: TProjectDetail) => {
      if (project?.id != item?.id) {
        setProject(item);
      }
    },
    [project]
  );

  const updateCategory = useCallback(
    (item?: ProjectCategory) => {
      if (category?.id != item?.id) {
        setCategory(item);
      }
    },
    [category]
  );

  return (
    <ResponsiveProvider mediaQueries={mediaQueries}>
      <ProjectContext.Provider
        value={{
          updateProjects,
          projects,
          updateProject,
          project,
          updateCategory,
          category,
        }}
      >
        <section className={cls.content}>
          <ProjectList />
          <aside className={cls.aside}>
            <button
              className={cls.asideTitle}
              onClick={() => setShowFilters(true)}
            >
              {translate('Filter')}
            </button>
            {showFilters && (
              <div className={cls.filtersWrapper}>
                <ProjectListFilters lang={lang} close={setShowFilters} />
              </div>
            )}
          </aside>
        </section>
        <TransitionGroup>
          {project && (
            <CSSTransition
              timeout={500}
              key={projectId}
              classNames={{
                enter: cls.pageEnter,
                enterActive: cls.pageEnterActive,
                exit: cls.pageExit,
                exitActive: cls.pageExitActive,
              }}
            >
              <div className={cls.page}>
                <ProjectDetail project={project} />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        {children}
      </ProjectContext.Provider>
    </ResponsiveProvider>
  );
};

export default WithProjects;
