import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';
import stylesheet, { headerHeight } from '../constants/stylesheet';
import fonts from '../constants/fonts';

const useStyles = makeStyles({ name: 'Detail' })(() =>
({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',

    background: 'white',
  },

  header: {
    display: 'flex',
    alignItems: 'center',

    [mediaQueries.Mobile]: {
      flex: ` 0 0 ${headerHeight.Mobile}`,

      padding: '0 1.5rem',
    },

    [mediaQueries.AfterMobile]: {
      position: 'relative',
      flex: ` 0 0 ${headerHeight.AfterMobile}`,

      padding: '0 1.46666rem',
      zIndex: 1,
    },
  },

  iconBtn: {
    flex: '0 0 auto',
    lineHeight: 0,

    [mediaQueries.AfterMobile]: {
      padding: '.5rem',
    },
  },

  thambnailsBtn: {
    // opacity: 0,
    // pointerEvents: 'none',
    // '&[data-active=""]': {
    //   opacity: 1,
    //   pointerEvents: 'initial',
    // },
  },

  headerContent: {
    flex: '1 1 auto',

    [mediaQueries.AfterMobile]: {
      display: 'flex',
      alignItems: 'center',
    },
  },

  title: {
    [mediaQueries.Mobile]: {
      textAlign: 'center',
      flex: '1 1 100%',
    },

    [mediaQueries.AfterMobile]: {
      textAlign: 'center',
      flex: '1 1 100%',

      '[data-type="project"] &': {
        flex: '1 1 50%',
        textAlign: 'right',
        marginRight: '1.5rem',
      },
    },
  },

  uppercaseTitle: {
    textTransform: 'uppercase',
  },

  headerInfoBtn: {
    [mediaQueries.Mobile]: {
      display: 'none',
    },

    [mediaQueries.AfterMobile]: {
      '[data-type="project"] &': {
        flex: '1 1 50%',
        textAlign: 'left',
        marginLeft: '1.5rem',
        marginRight: '2rem',
      },
    },
  },

  projectIntro: {
    position: 'fixed',
    inset: 0,
    background: 'white',

    display: 'grid',
    alignItems: 'center',
    zIndex: 10,

    [mediaQueries.Mobile]: {
      padding: '2rem',
    },

    [mediaQueries.AfterMobile]: {
      padding: '5rem',
    },
  },

  projectIntroFont: {
    ...fonts.Ligth,
    ...stylesheet.Display,
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  projectIntroEnter: {
    opacity: 0,
  },

  projectIntroEnterActive: {
    opacity: 1,
    transition: 'opacity 500ms',
  },

  projectIntroExit: {
    opacity: 1,
  },

  projectIntroExitActive: {
    opacity: 0,
    transition: 'opacity 500ms',
  },

  content: {
    flex: '1 1 100%',
  },

  overlay: {
    position: 'fixed',
    inset: 0,

    opacity: 0,
    transition: 'opacity .3s ease',
    willChange: 'opacity',

    zIndex: 10,
    pointerEvents: 'none',

    '&[data-show="true"]': {
      opacity: 1,
      pointerEvents: 'initial',
    },
  }
}));

export default useStyles;
