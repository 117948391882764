import { makeStyles } from "tss-react/mui";
import { Link } from 'gatsby';
import React, { FC } from 'react';

const useStyles = makeStyles({ name: 'CustomLink' })(
  () =>({
      link: {
        position: 'absolute',
        inset: '0',
      },
    })
);

const CustomLink: FC<{ link: string }> = ({ link }) => {
  const {classes: cls} = useStyles();

  if (!link) {
    return null;
  }

  if (link.startsWith('http')) {
    return <a href={link} className={cls.link} target='_blank' />;
  }

  return <Link to={link} className={cls.link} />;
};

export default CustomLink;
