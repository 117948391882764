import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = makeStyles({ name: 'SideBySideImageSlide' })(() =>
({
  root: {
    [mediaQueries.AfterMobile]: {
      display: 'flex',

      height: '100%',
    },
  },

  slide: {
    position: 'relative',

    [mediaQueries.AfterMobile]: {
      flex: '1 1 100%',

      '&:first-of-type': {
        marginRight: '1.5rem',
        marginLeft: '5rem',
      },
      '&:last-child': {
        marginLeft: '1.5rem',
        marginRight: '5rem',
      },
    },
  }
}));

export default useStyles;
