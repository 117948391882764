import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { headerHeight } from '../../constants/stylesheet';
import mediaQueries from '../../constants/mediaQueries';

const useStyles = makeStyles({ name: 'SlideBlockImage' })(() =>
({
  root: {
    height: '100%',
    position: 'relative',
  },

  figure: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  }
}));

export default useStyles;
