import {
  TExplorationSlides,
  TThumbnailExploration,
  TSlideSingleImage,
  TSlideSideBySideImage,
  TSlideVideo,
} from '../@types';

export default (slides: TExplorationSlides[]) => {
  return slides
    .filter(({ primary }) => !!primary)
    .reduce((prev, cur) => {
      switch (cur.slice_type) {
        case 'single_image':
          const { single_image } = cur.primary as TSlideSingleImage;
          return [
            ...prev,
            {
              alt: single_image.alt || '',
              image: single_image.gatsbyImageData,
              id: cur.id,
              customId: cur.id,
            },
          ];

        case 'side_by_side_image':
          const { left_image, right_image } =
            cur.primary as TSlideSideBySideImage;
          return [
            ...prev,
            {
              alt: left_image.alt || '',
              image: left_image.gatsbyImageData,
              id: `${cur.id}`,
              customId: `${cur.id}-left`,
            },
            {
              alt: right_image.alt || '',
              image: right_image.gatsbyImageData,
              id: `${cur.id}`,
              customId: `${cur.id}-right`,
            },
          ];

        case 'video':
          const { video_thumbnail } = cur.primary as TSlideVideo;
          return [
            ...prev,
            {
              id: cur.id,
              customId: cur.id,
              alt: video_thumbnail.alt || '',
              image: video_thumbnail.gatsbyImageData,
            },
          ];

        default:
          return prev;
      }
    }, [] as Array<TThumbnailExploration>);
};
