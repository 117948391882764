import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
  TExplorationSlides,
  TSlideSingleImage,
  TSlideSideBySideImage,
  TSlideVideo,
  TVideoBlock,
  TSlideLink,
} from '../@types';

export type ExplorationSlideFlattened = {
  id: string;
  customId: string;
  alt?: string;
  image?: IGatsbyImageData;
  video?: TVideoBlock;
  caption?: string;
  slide_link: TSlideLink;
};

export default (slides: TExplorationSlides[]) => {
  return slides
    .filter(({ primary }) => !!primary)
    .reduce((prev, cur) => {
      switch (cur.slice_type) {
        case 'single_image':
          const slideImage = cur.primary as TSlideSingleImage;
          const { single_image } = slideImage;
          return [
            ...prev,
            {
              alt: single_image.alt || '',
              image: single_image.gatsbyImageData,
              id: cur.id,
              customId: cur.id,
              caption: slideImage?.caption,
              slide_link: slideImage?.slide_link,
            },
          ];

        case 'side_by_side_image':
          const {
            left_image,
            right_image,
            caption: imgCaption,
            slide_link: slideLink,
          } = cur.primary as TSlideSideBySideImage;
          return [
            ...prev,
            {
              alt: left_image.alt || '',
              image: left_image.gatsbyImageData,
              id: `${cur.id}-left`,
              customId: `${cur.id}-left`,
              caption: imgCaption,
              slide_link: slideLink,
            },
            {
              alt: right_image.alt || '',
              image: right_image.gatsbyImageData,
              id: `${cur.id}-right`,
              customId: `${cur.id}-right`,
              caption: imgCaption,
              slide_link: slideLink,
            },
          ];

        case 'video':
          const { video, caption, slide_link } = cur.primary as TSlideVideo;
          return [
            ...prev,
            {
              id: cur.id,
              customId: cur.id,
              video: video,
              caption: caption,
              slide_link: slide_link,
            },
          ];

        default:
          return prev;
      }
    }, [] as Array<ExplorationSlideFlattened>);
};
