import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { Dispatch, FC } from 'react';
import linkResolver from '../utils/linkResolver';
import { RichText } from 'prismic-reactjs';
import { useI18n } from '../@context/i18n';
import { useResponsive } from '../@context/Responsive';
import { TIntroduction, TInformations, TTitleField } from '../@types';
import Close from './Icons/Close';
import useStyles from './ProjectInformation.styles';
import { locales } from '../gatsby/createPages';

const ProjectInformation: FC<{
  title?: TTitleField;
  introduction?: TIntroduction;
  informations?: TInformations;
  close: Dispatch<boolean>;
  lang: string;
}> = ({ title, introduction, informations, close, lang }) => {
  const { classes: cls } = useStyles();
  const { steps } = useResponsive();
  const { translate } = useI18n();

  return (
    <section className={cls.root} onClick={() => close(false)}>
      <header className={cls.header}>
        <Link to={`/${locales[lang]}/projects`} className={cls.iconBtn}>
          <Close />
        </Link>
        <h3 className={cls.title}>{title?.text}</h3>
      </header>
      <article className={cls.contentWrapper}>
        <div className={cls.infoWrapper}>
          <button onClick={() => close(false)} className={cls.info}>
            {translate('Images')}
          </button>
        </div>
        <div className={cls.content}>
          {steps.includes('Mobile') && (
            <button
              onClick={() => close(false)}
              className={cls.imagesMobileButton}
            >
              {translate('Images')}
            </button>
          )}
          {introduction?.richText && (
            <div className={cls.intro}>
              <RichText render={introduction.richText} linkResolver={linkResolver} />
            </div>
          )}
          {informations && (
            <div
              className={classNames(
                cls.informationsItems,
                cls.informationsItemsFont
              )}
            >
              {informations.map(({ info_content, info_title }, idx) => (
                <div key={idx} className={cls.informationsItem}>
                  <div className={cls.informationTitle}>{info_title.text}</div>
                  <div className={cls.informationContent}>
                    <RichText
                      render={info_content.richText}
                      linkResolver={linkResolver}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </article>
    </section>
  );
};

export default ProjectInformation;
