import { GatsbyNode } from 'gatsby';
import path from 'path';
import {
  AtelierPage,
  TExplorationDetail,
  LangEnum,
  ProjectCategory,
  ProjectListItem,
  PrismicLangEnum,
} from '../@types';
import { getCategories } from '../utils/getFiltersFromNodes';
import {
  projects as projectsQuery,
  explorations as explorationsQuery,
} from './createPages.graphql';

export type PageContext = {
  lang: PrismicLangEnum;
  layout?: string;
};

export type ProjectPageContext = PageContext & {
  projectId?: string;
  category?: ProjectCategory;
};

export type ExplorationPageContext = PageContext & {
  explorationId?: string;
};

export type AtelierPageContext = PageContext & {
  atelier?: AtelierPage;
};

type TQueryExplorationDetail = {
  allPrismicExploration: {
    nodes: TExplorationDetail[];
  };
};
type TQueryProjects = {
  allPrismicProject: {
    nodes: ProjectListItem[];
  };
};

export const locales: Record<PrismicLangEnum, LangEnum> = { 'fr-fr': 'fr' };
export const createPages: GatsbyNode['createPages'] = async ({
  graphql,
  actions,
}) => {
  const { createPage } = actions;
  const componentHome = path.resolve(__dirname, '../templates/Home.tsx');
  const componentProject = path.resolve(__dirname, '../templates/Project.tsx');
  const componentProjectList = path.resolve(
    __dirname,
    '../templates/Projects.tsx'
  );
  const componentExplorationList = path.resolve(
    __dirname,
    '../templates/ExplorationList.tsx'
  );
  const componentExploration = path.resolve(
    __dirname,
    '../templates/Exploration.tsx'
  );
  const componentAtelier = path.resolve(__dirname, '../templates/Atelier.tsx');

  const projectsData = await graphql<TQueryProjects>(projectsQuery);
  const explorationsData = await graphql<TQueryExplorationDetail>(
    explorationsQuery
  );

  const allExplorations = explorationsData.data!.allPrismicExploration.nodes;
  const allProjects = projectsData.data!.allPrismicProject.nodes;
  createPage({
    path: `/`,
    component: componentHome,
    context: {
      layout: 'homepage',
      lang: Object.keys(locales)[0],
    },
  });

  for (const [prismicLang, lang] of Object.entries(locales)) {
    createPage({
      path: `/${lang}`,
      component: componentHome,
      context: {
        layout: 'homepage',
        lang: prismicLang,
      },
    });

    createPage({
      path: `/${lang}/atelier`,
      component: componentAtelier,
      context: {
        layout: 'atelier',
        lang: prismicLang,
      } as AtelierPageContext,
    });

    const projects = allProjects.filter(
      ({ lang: projectLang }) => projectLang === prismicLang
    );
    const categories = getCategories(projects) || {};

    createPage({
      path: `/${lang}/projects`,
      component: componentProjectList,
      context: {
        layout: 'project',
        lang: prismicLang,
      } as ProjectPageContext,
    });

    for (const category of Object.values(categories)) {
      createPage({
        path: `/${lang}/projects/${category.uid}`,
        component: componentProjectList,
        context: {
          layout: 'project',
          lang: prismicLang,
          category,
          canonical: `/${lang}/projects`,
        },
      });
    }

    for (const projectItem of projects) {
      const { uid, id } = projectItem;
      createPage({
        path: `/${lang}/projects/${uid}`,
        component: componentProject,
        context: {
          layout: 'project',
          lang: prismicLang,
          id,
          projectId: id,
        } as ProjectPageContext,
      });

      for (const category of Object.values(categories)) {
        createPage({
          path: `/${lang}/projects/${category.uid}/${uid}`,
          component: componentProject,
          context: {
            layout: 'project',
            lang: prismicLang,
            id,
            projectId: id,
            category,
            canonical: `/${lang}/projects/${uid}`,
          },
        });
      }
    }

    const explorations = allExplorations.filter(
      ({ lang: exploLang }) => exploLang === prismicLang
    );

    createPage({
      path: `/${lang}/transformation`,
      component: componentExplorationList,
      context: {
        layout: 'exploration',
        lang: prismicLang,
      } as ExplorationPageContext,
    });

    for (const exploration of explorations) {
      const { uid, id } = exploration;

      createPage({
        path: `/${lang}/transformation/${uid}`,
        component: componentExploration,
        context: {
          layout: 'exploration',
          lang: prismicLang,
          id,
          explorationId: id,
        },
      });
    }
  }
};
