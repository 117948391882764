export default {
  Regular: {
    fontFamily: 'Cambon',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  Ligth: {
    fontFamily: 'Cambon',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  Italis: {
    fontFamily: 'Cambon',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'italic',
    fontDisplay: 'swap',
  },
};
