import mediaQueries from './mediaQueries';
import fonts from './fonts';

export const bodyFontSize = {
  Mobile: 14,
  TabletSmallLaptop: 15,
  Desktop: 15,
  BigDesktop: 15,
};

export const bodyLineHeight = {
  Mobile: '1.3rem',
  TabletSmallLaptop: '1.25rem',
  Desktop: '1.25rem',
  BigDesktop: '1.25rem',
};

export const headerHeight = {
  Mobile: '4rem',
  AfterMobile: '5rem',
};

export const blockSpacing = {
  Mobile: '0 2rem 4rem',
  AfterMobile: '6rem 5rem 5.33333rem',
};

export const homeBlockMaxWidth = '585px';

export default {
  Text: {
    ...fonts.Regular,

    [mediaQueries.Mobile]: {
      fontSize: bodyFontSize.Mobile,
      lineHeight: bodyLineHeight.Mobile,
    },
    [mediaQueries.TabletSmallLaptop]: {
      fontSize: bodyFontSize.TabletSmallLaptop,
      lineHeight: bodyLineHeight.TabletSmallLaptop,
    },
    [mediaQueries.Desktop]: {
      fontSize: bodyFontSize.Desktop,
      lineHeight: bodyLineHeight.Desktop,
    },
    [mediaQueries.BigDesktop]: {
      fontSize: bodyFontSize.BigDesktop,
      lineHeight: bodyLineHeight.BigDesktop,
    },
  },
  Large: {
    fontWeight: 300,
    [mediaQueries.Mobile]: {
      fontSize: '1.25rem',
      lineHeight: '1.625rem',
    },
    [mediaQueries.AfterMobile]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
  Title: {
    fontWeight: 300,
    [mediaQueries.Mobile]: {
      fontSize: '2rem',
      lineHeight: '2rem',
    },
    [mediaQueries.AfterMobile]: {
      fontSize: '2.5rem',
      lineHeight: '3rem',
    },
  },
  Display: {
    fontWeight: 300,
    [mediaQueries.Mobile]: {
      fontSize: '2.5rem',
      lineHeight: '2.5rem',
    },
    [mediaQueries.AfterMobile]: {
      fontSize: 'calc(7vw - (10rem/7))',
      lineHeight: 'calc(7vw - (10rem/7))',
    },
  },
  Infos: {
    textTransform: 'uppercase' as 'uppercase',

    [mediaQueries.Mobile]: {
      fontSize: '.85rem',
      lineHeight: '1rem',
    },
  },
  Anim: {
    transform: 'translateY(50px)',
    opacity: 0,
    willChange: 'transform, opacity',
    transition: 'all .5s ease',

    [mediaQueries.Mobile]: {
      transform: 'translateY(50px)',
    },
    [mediaQueries.AfterMobile]: {
      transform: 'translateY(100px)',
    },

    '&[data-anim="true"]': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  LinkAnim: {
    willChange: 'opacity',
    transition: 'opacity 0.25s',

    '&:hover': {
      opacity: 0.5,
    },
  },
  ObjectFitCover: {
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"',
  },
  ObjectFitContain: {
    objectFit: 'contain',
    fontFamily: '"object-fit: contain;"',
  },
};
