import React from 'react';
import { makeStyles } from 'tss-react/mui';
import mediaQueries from '../constants/mediaQueries';
import stylesheet from '../constants/stylesheet';
import closePng from '../images/close-cursor.png';

const useStyles = makeStyles<void, 'item'>({ name: 'ProjectListFilters' })((_theme, _params, classes) => ({
  root: {
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '&': {
      cursor: `url(${closePng}),e-resize`,
    },
  },
  item: {
    textTransform: 'uppercase',

    [mediaQueries.Mobile]: {
      ...stylesheet.Display,
      marginBottom: '.5rem',
    },
    [mediaQueries.AfterMobile]: {
      ...stylesheet.Title,
    },
  },
  sup: {
    position: 'absolute',
    WebkitFontFeatureSettings: '"frac"',
    MozFontFeatureSettings: '"frac"',
    '-ms-font-feature-settings': '"frac"',
    fontFeatureSettings: '"frac"',
    fontWeight: 300,

    [mediaQueries.AfterMobile]: {
      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .4s ease',

      [`.${classes.item}:hover &`]: {
        opacity: 1,
      },
    },
  },
}));

export default useStyles;
